<template>
	<svg
		width="15"
		height="8"
		viewBox="0 0 15 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.98016 7.05739C7.70202 7.28916 7.29802 7.28916 7.01988 7.05739L1.01988 2.05739C0.701673 1.79221 0.65868 1.31929 0.923853 1.00108C1.18903 0.682874 1.66195 0.639881 1.98016 0.905054L7.50002 5.50494L13.0199 0.905054C13.3381 0.639881 13.811 0.682874 14.0762 1.00108C14.3414 1.31929 14.2984 1.79221 13.9802 2.05739L7.98016 7.05739Z"
			fill="#0C1026"
		/>
	</svg>
</template>

<script>
export default defineNuxtComponent({
	name: "svgChevronUp",
});
</script>
