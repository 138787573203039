<template>
	<svg
		width="15"
		height="8"
		viewBox="0 0 15 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.51988 0.423834C6.79801 0.192055 7.20202 0.192055 7.48016 0.423834L13.4802 5.42383C13.7984 5.68901 13.8414 6.16193 13.5762 6.48014C13.311 6.79835 12.8381 6.84134 12.5199 6.57617L7.00002 1.97628L1.48016 6.57617C1.16195 6.84134 0.689026 6.79835 0.423853 6.48014C0.15868 6.16193 0.201673 5.68901 0.519881 5.42383L6.51988 0.423834Z"
			fill="#0C1026"
		/>
	</svg>
</template>

<script>
export default defineNuxtComponent({
	name: "svgChevronDown",
});
</script>
