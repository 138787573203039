<template>
	<section
		class="sorting"
		@mouseleave="showOptions = false"
	>
		<div class="sorting-dropdown">
			<TnButton
				class="sorting-button"
				icon-left="sort"
				size="xs"
				tertiary
				@click="showOptions = !showOptions"
			>
				<span class="margin-right-s">Sorter<span class="extra-info"> etter</span>:</span>
				<span class="margin-right-s sorting-label">{{ selectedOption.label }}</span>
				<SvgChevronDown v-if="showOptions" />
				<SvgChevronUp v-else />
			</TnButton>
			<div
				class="sorting-dropdown-content"
				v-if="showOptions"
			>
				<a
					v-for="(option, index) in options"
					:key="`option-${index}`"
					href="#"
					@click.prevent="handleClick(option.value, index)"
					>{{ option.label }}</a
				>
			</div>
		</div>
	</section>
</template>

<script>
export default defineNuxtComponent({
	name: "Sorting",

	components: {
		svgChevronUp: markRaw(defineAsyncComponent(() => import("../svg/svgChevronUp.vue?component"))),
		svgChevronDown: markRaw(defineAsyncComponent(() => import("../svg/svgChevronDown?component.vue"))),
	},

	data() {
		return {
			showOptions: false,
			selectedIndex: null,
		};
	},

	props: ["options"],

	computed: {
		selectedOption() {
			return this.options[this.selectedIndex] || this.options[0];
		},
	},

	methods: {
		handleClick(option, index) {
			this.selectedIndex = index;
			this.$emit("update:modelValue", option);
			this.showOptions = !this.showOptions;
		},
	},
});
</script>

<style lang="scss" scoped>
.sorting {
	white-space: nowrap;

	.sorting-button {
		margin-bottom: $spacing-s;
	}

	&-button {
		background-color: transparent;
		border-radius: 8px !important;
		color: $color-cta-default;
		padding: 5px 20px;
		border: 2px solid $color-cta-default;
		cursor: pointer;
		text-align: right;

		@include font-text-s;
	}

	.extra-info {
		@include breakpoint(mobile) {
			display: none;
		}
	}

	&-label {
		@include font-text-bold-s;
	}

	&-dropdown {
		position: relative;
		display: inline-block;
		z-index: 1001;

		&-content {
			right: 0;
			position: absolute;
			min-width: 160px;
			z-index: 1;
			background: #fff;
			border-radius: 2px;

			@include font-text-s;
			@include shadow(l);

			a {
				color: black;
				padding: 12px 16px;
				text-decoration: none;
				display: block;

				&:hover {
					background-color: #ddd;
				}
			}
		}
	}
}
</style>
